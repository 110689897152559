import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-content',
    template: `
    <!-- <div class="modal-header">
        <h5 class="modal-title text-center">Calculating Score...</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body"> 
    <img src="https://media.giphy.com/media/JVGLHEuzbVviw/giphy.gif" alt="funny GIF" width="100%">
  
    </div>
    <!-- <div class="modal-footer">
        <div class="left-side">
            <button type="button" class="btn btn-default btn-link" (click)="activeModal.close('Close click')">Never mind</button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
            <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">DELETE</button>
        </div>
    </div> -->
    `
  })
  export class NgbdModalContent {
    @Input() name;
  
    constructor(public activeModal: NgbActiveModal) {}
  }

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    public sentiment = null;
    public isPositive = false;
    public isNeutral = false;
    public isNegative = false;

    public selectedFile = null;

    public closeness = null;
    public isOne = false;
    public isTwo = false;
    public isThree = false;
    public isFour = false;
    public isFive = false;
    public isSix = false;
    public isSeven = false;

    public closeness1 = null;
    public isOne1 = false;
    public isTwo1 = false;
    public isThree1 = false;
    public isFour1 = false;
    public isFive1 = false;
    public isSix1 = false;
    public isSeven1 = false;
    public closeness2 = null;
    public isOne2 = false;
    public isTwo2 = false;
    public isThree2 = false;
    public isFour2 = false;
    public isFive2 = false;
    public isSix2 = false;
    public isSeven2 = false;

    public score = null;
    private noise = 0;

    constructor(private router:Router, private modalService: NgbModal) { }

    ngOnInit() {}

    // Sentiment

    positiveSentimentCardClass(){
        if (this.isPositive == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onPositiveSentimentSelected(){
        this.sentiment = 1
        console.log(this.sentiment)

        this.isPositive = true;
        this.isNeutral = false;
        this.isNegative = false;
    }

    neutralSentimentCardClass(){
        if (this.isNeutral == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onNeutralSentimentSelected(){
        this.sentiment = 0
        console.log(this.sentiment)

        this.isPositive = false;
        this.isNeutral = true;
        this.isNegative = false;
    }

    negativeSentimentCardClass(){
        if (this.isNegative == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onNegativeSentimentSelected(){
        this.sentiment = -1
        console.log(this.sentiment)

        this.isPositive = false;
        this.isNeutral = false;
        this.isNegative = true;
    }

    // File Upload

    detectFile(event) {
        this.selectedFile = event.target.files;
        
    }
    
    // Closeness: Self

    oneClosenessCardClass(){
        if (this.isOne == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onOneClosenessSelected(){
        this.closeness = 1
        console.log(this.closeness)

        this.isOne = true;
        this.isTwo = false;
        this.isThree = false;
        this.isFour = false;
        this.isFive = false;
        this.isSix = false;
        this.isSeven = false;
    }

    twoClosenessCardClass(){
        if (this.isTwo == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onTwoClosenessSelected(){
        this.closeness = 2
        console.log(this.closeness)

        this.isOne = false;
        this.isTwo = true;
        this.isThree = false;
        this.isFour = false;
        this.isFive = false;
        this.isSix = false;
        this.isSeven = false;
    }

    threeClosenessCardClass(){
        if (this.isThree == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onThreeClosenessSelected(){
        this.closeness = 3
        console.log(this.closeness)

        this.isOne = false;
        this.isTwo = false;
        this.isThree = true;
        this.isFour = false;
        this.isFive = false;
        this.isSix = false;
        this.isSeven = false;
    }

    fourClosenessCardClass(){
        if (this.isFour == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onFourClosenessSelected(){
        this.closeness = 4
        console.log(this.closeness)

        this.isOne = false;
        this.isTwo = false;
        this.isThree = false;
        this.isFour = true;
        this.isFive = false;
        this.isSix = false;
        this.isSeven = false;
    }

    fiveClosenessCardClass(){
        if (this.isFive == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onFiveClosenessSelected(){
        this.closeness = 5
        console.log(this.closeness)

        this.isOne = false;
        this.isTwo = false;
        this.isThree = false;
        this.isFour = false;
        this.isFive = true;
        this.isSix = false;
        this.isSeven = false;
    }

    sixClosenessCardClass(){
        if (this.isSix == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onSixClosenessSelected(){
        this.closeness = 6
        console.log(this.closeness)

        this.isOne = false;
        this.isTwo = false;
        this.isThree = false;
        this.isFour = false;
        this.isFive = false;
        this.isSix = true;
        this.isSeven = false;
    }

    sevenClosenessCardClass(){
        if (this.isSeven == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onSevenClosenessSelected(){
        this.closeness = 7
        console.log(this.closeness)

        this.isOne = false;
        this.isTwo = false;
        this.isThree = false;
        this.isFour = false;
        this.isFive = false;
        this.isSix = false;
        this.isSeven = true;
    }

    // Closeness: Other

    oneClosenessCardClass1(){
        if (this.isOne1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onOneClosenessSelected1(){
        this.closeness1 = 1
        // console.log(this.closeness)

        this.isOne1 = true;
        this.isTwo1 = false;
        this.isThree1 = false;
        this.isFour1 = false;
        this.isFive1 = false;
        this.isSix1 = false;
        this.isSeven1 = false;
    }

    twoClosenessCardClass1(){
        if (this.isTwo1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onTwoClosenessSelected1(){
        this.closeness1 = 2
        // console.log(this.closeness)

        this.isOne1 = false;
        this.isTwo1 = true;
        this.isThree1 = false;
        this.isFour1 = false;
        this.isFive1 = false;
        this.isSix1 = false;
        this.isSeven1 = false;
    }

    threeClosenessCardClass1(){
        if (this.isThree1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onThreeClosenessSelected1(){
        this.closeness1 = 3
        // console.log(this.closeness)

        this.isOne1 = false;
        this.isTwo1 = false;
        this.isThree1 = true;
        this.isFour1 = false;
        this.isFive1 = false;
        this.isSix1 = false;
        this.isSeven1 = false;
    }

    fourClosenessCardClass1(){
        if (this.isFour1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onFourClosenessSelected1(){
        this.closeness1 = 4
        // console.log(this.closeness)

        this.isOne1 = false;
        this.isTwo1 = false;
        this.isThree1 = false;
        this.isFour1 = true;
        this.isFive1 = false;
        this.isSix1 = false;
        this.isSeven1 = false;
    }

    fiveClosenessCardClass1(){
        if (this.isFive1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onFiveClosenessSelected1(){
        this.closeness1 = 5
        // console.log(this.closeness)

        this.isOne1 = false;
        this.isTwo1 = false;
        this.isThree1 = false;
        this.isFour1 = false;
        this.isFive1 = true;
        this.isSix1 = false;
        this.isSeven1 = false;
    }

    sixClosenessCardClass1(){
        if (this.isSix1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onSixClosenessSelected1(){
        this.closeness1 = 6
        // console.log(this.closeness)

        this.isOne1 = false;
        this.isTwo1 = false;
        this.isThree1 = false;
        this.isFour1 = false;
        this.isFive1 = false;
        this.isSix1 = true;
        this.isSeven1 = false;
    }

    sevenClosenessCardClass1(){
        if (this.isSeven1 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onSevenClosenessSelected1(){
        this.closeness1 = 7
        // console.log(this.closeness)

        this.isOne1 = false;
        this.isTwo1 = false;
        this.isThree1 = false;
        this.isFour1 = false;
        this.isFive1 = false;
        this.isSix1 = false;
        this.isSeven1 = true;
    }

    // Closeness: Others

    oneClosenessCardClass2(){
        if (this.isOne2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onOneClosenessSelected2(){
        this.closeness2 = 1
        // console.log(this.closeness)

        this.isOne2 = true;
        this.isTwo2 = false;
        this.isThree2 = false;
        this.isFour2 = false;
        this.isFive2 = false;
        this.isSix2 = false;
        this.isSeven2 = false;
    }

    twoClosenessCardClass2(){
        if (this.isTwo2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onTwoClosenessSelected2(){
        this.closeness2 = 2
        // console.log(this.closeness)

        this.isOne2 = false;
        this.isTwo2 = true;
        this.isThree2 = false;
        this.isFour2 = false;
        this.isFive2 = false;
        this.isSix2 = false;
        this.isSeven2 = false;
    }

    threeClosenessCardClass2(){
        if (this.isThree2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onThreeClosenessSelected2(){
        this.closeness2 = 3
        // console.log(this.closeness)

        this.isOne2 = false;
        this.isTwo2 = false;
        this.isThree2 = true;
        this.isFour2 = false;
        this.isFive2 = false;
        this.isSix2 = false;
        this.isSeven2 = false;
    }

    fourClosenessCardClass2(){
        if (this.isFour2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onFourClosenessSelected2(){
        this.closeness2 = 4
        // console.log(this.closeness)

        this.isOne2 = false;
        this.isTwo2 = false;
        this.isThree2 = false;
        this.isFour2 = true;
        this.isFive2 = false;
        this.isSix2 = false;
        this.isSeven2 = false;
    }

    fiveClosenessCardClass2(){
        if (this.isFive2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onFiveClosenessSelected2(){
        this.closeness2 = 5
        // console.log(this.closeness)

        this.isOne2 = false;
        this.isTwo2 = false;
        this.isThree2 = false;
        this.isFour2 = false;
        this.isFive2 = true;
        this.isSix2 = false;
        this.isSeven2 = false;
    }

    sixClosenessCardClass2(){
        if (this.isSix2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onSixClosenessSelected2(){
        this.closeness2 = 6
        // console.log(this.closeness)

        this.isOne2 = false;
        this.isTwo2 = false;
        this.isThree2 = false;
        this.isFour2 = false;
        this.isFive2 = false;
        this.isSix2 = true;
        this.isSeven2 = false;
    }

    sevenClosenessCardClass2(){
        if (this.isSeven2 == true){
        return ['text-primary ', 'has-success', 'border-primary']
        }
    }

    onSevenClosenessSelected2(){
        this.closeness2 = 7
        // console.log(this.closeness)

        this.isOne2 = false;
        this.isTwo2 = false;
        this.isThree2 = false;
        this.isFour2 = false;
        this.isFive2 = false;
        this.isSix2 = false;
        this.isSeven2 = true;
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }
    
      friendOMeterScore(sentiment, closeness, g){
        console.log("Sentiment: " + sentiment)
        console.log("Closeness: " + closeness)
        console.log("Noise parameter: ", g)
    
        if(sentiment !== 0){
          this.noise = this.getRandomArbitrary(0,g)
        } else{
          this.noise = this.getRandomArbitrary(-g,g)
        }
    
        console.log("Noise: ", this.noise)
    
        this.score = ((closeness+1) * .11) + this.noise
    
      }

      submit(){
        if(this.sentiment === null){
          alert("Please tell the Friend-O-Meter More!")
        } else if(this.selectedFile === null){ 
          alert("Please upload a screenshot of texts between you and your friend you would like to analyze.")
        } else if(this.closeness === null){
          alert("Please tell Friend-O-Meter how would you rate your relationship with your friend?")
        } else if(this.closeness1 === null){
            alert("Please tell Friend-O-Meter how would your friend would rate your relationship?")
        } else if(this.closeness2 === null){
            alert("Please tell Friend-O-Meter how would others would rate your relationship with your friend?")
        } else{
          this.friendOMeterScore(this.sentiment, this.closeness, .1)
          console.log("Friend-O-Meter Score: " + this.score)
    
          this.modalService.open(NgbdModalContent);
          
          setTimeout(() => {
            if(this.sentiment === 1){
              this.router.navigate(['/iijabiun'], { queryParams: { session: this.score} });
              this.modalService.dismissAll();
            } else if(this.sentiment === 0){
              this.router.navigate(['/hiadiun'], { queryParams: { session: this.score} });
              this.modalService.dismissAll();
            } else if(this.sentiment === -1){
              this.router.navigate(['/nafy'], { queryParams: { session: this.score} });
              this.modalService.dismissAll();
            }
        }, 5000);  //5s
        }
    }
        




}
