import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-hiadiun',
  templateUrl: './hiadiun.component.html',
  styleUrls: ['./hiadiun.component.css']
})
export class HiadiunComponent implements OnInit {

  private score = null;

  constructor(private router:Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.score = params.session;
    }
  );
  }

  isFriendOMeterOneVisible(){
    if(this.score < .1666667){
      return true
    }
  }

  isFriendOMeterTwoVisible(){
    if(this.score > .1666667 && this.score < .333333333){
      return true
    }
  }

  isFriendOMeterThreeVisible(){
    if(this.score > .333333333 && this.score < .5){
      return true
    }
  }

  isFriendOMeterFourVisible(){
    if(this.score > .5 && this.score < .6666667){
      return true
    }
  }

  isFriendOMeterFiveVisible(){
    if(this.score > .6666667 && this.score < .833333333){
      return true
    }
  }

  isFriendOMeterSixVisible(){
    if(this.score > .833333333){
      return true
    }
  }

}
