import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { IijabiunComponent } from './iijabiun/iijabiun.component';
import { NafyComponent } from './nafy/nafy.component';
import { HiadiunComponent } from './hiadiun/hiadiun.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'iijabiun',             component: IijabiunComponent },
    { path: 'hiadiun',             component: HiadiunComponent },
    { path: 'nafy',             component: NafyComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
